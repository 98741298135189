import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HealthSafetyItem from '../items/HealthSafetyItem'
import {connect} from 'react-redux'
import {fetchHealthSafety} from '../../actions'
import {BASE_URL} from '../../path'

class HealthSafetyListSection extends React.Component{

    componentDidMount(){
        if(!this.props.pdfs){
            this.props.fetchHealthSafety(null)
        }
    }

    renderList(){
        return this.props.pdfs.data.map((item, index) => {
            if(item.title){
                return (
                    <React.Fragment>
                        <Col sm={12} md={6} lg={4} xl={4} >
                            <HealthSafetyItem 
                                date={item.published}
                                text={item.title}
                                link={`${BASE_URL}${item.pdf}`}
                                image={item.image}
                            />
                        </Col>
                    </React.Fragment>
                )
            }        
            return <React.Fragment key={index}></React.Fragment>    
        })
    }

    render(){
        return(
            <section className="mb_news-list-section mb_health-safety-list">
                <Container>
                    <Row>
                        {this.props.pdfs && this.renderList()}
                    </Row>
                </Container>
            </section>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        pdfs: state.healthSafetyState.data
    }
}

export default connect(mapStateToProps, {
    fetchHealthSafety
})(HealthSafetyListSection)