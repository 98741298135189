import {
    FETCH_DOCUMENTS
} from '../actions/types'
import _ from 'lodash'

export default (state = {}, action) => {
    switch (action.type) {
        case FETCH_DOCUMENTS:
            return {
                ...state, ..._.mapKeys(action.payload, 'name')
            }
        default:
            return state
    }
}