import React from 'react'
import {
    Link
} from 'react-router-dom'
import { BASE_URL } from '../../path'

class NewsItem extends React.Component {
    
    render(){

        const {date, text, link, image} = this.props

        console.log(BASE_URL + image)
        
        return(
            <div className="mb_news-item">
                <article>
                    {image && <img src={BASE_URL+image} alt="" />}
                    <span className="mb_paragraph-3">{date}</span>
                    <h4 className="mb_heading-3-1">{text}</h4>
                    <Link className="mb_read-more-link" to={link}>Read More</Link>
                </article>   
            </div>
        )
    }
}

export default NewsItem