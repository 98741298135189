import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '../assets/img/mba-logo.svg'
import mbsaLogo from '../assets/img/mbsa-logo@2x.jpg'
import femLogo from '../assets/img/fem-logo@2x.jpg'
import tbibcLogo from '../assets/img/BIBC-Logo-White.jpg'
import {CopyWrightLinks} from './NavLinks'
import SignUpSection from './sections/SignUpSection'
import {connect} from 'react-redux'
import {BASE_URL} from '../path'

//pdfs
import pdf from '../assets/pdfs/MBAWC-Confirmation-Report-2023.pdf'
import pdf1 from '../assets/pdfs/Master-Builders-Western-Cape-Constitution-2021_FINAL.pdf'
import pdf2 from '../assets/pdfs/COVID19-Policy-28-May-2020.pdf'
import pdf3 from '../assets/pdfs/VAT-Notice-of-Registration-MBA.pdf'
import pdf4 from '../assets/pdfs/Association-Registration-Builders-Association-Western-Cape.pdf'
import pdf5 from '../assets/pdfs/MBAWC_Customer-Privacy-Notice-and-Consent-to-Use-of-Personal-Information.pdf'

class Footer extends React.Component {
    render(){

        const bee_certificate = this.props.documents['bee-certificate'] || null
        const vat_registration = this.props.documents['vat-registration'] || null
        const company_registration = this.props.documents['company-registration'] || null
        const constitution = this.props.documents['constitution'] || null
        const covid_19_policy = this.props.documents['covid-19-policy'] || null
        const popia_consent = this.props.documents['popia-consent-to-use-of-personal-information'] || null 

        return(
            <React.Fragment>
            <SignUpSection />
            <footer className="mb_footer">
                <Container>
                    <Row>
                        
                        <Col sm={12} md={12} lg={5} xl={5}>
                            <Row>
                                <Col sm={12} md={3} lg={5}>
                                    <img className="mb_logo-footer" src={logo} alt="Master Builders and Allied Trades Association Western Cape" title="Master Builders and Allied Trades Association Western Cape" />
                                </Col>
                                <Col sm={12} md={9} lg={7}>
                                    <h4 className="mb_heading-3">ABOUT MBAWC</h4>
                                    <div className="mb_border-bottom-left"></div>
                                    <p className="mb_paragraph">
                                        The MBAWC is a registered Trade Association for Employers in the Building Industry.
                                    </p>
                                </Col>
                            </Row>
                            {/*<Row>
                                <Col lg={12}>
                                    <p className="mb_reg-nr">
                                        BEE Certificate | VAT Number: 4130105572 | Company Registration Number: LR2/3/6/3
                                    </p>
                                </Col>
                            </Row>*/}
                        </Col>
                        <Col sm={12} md={4} lg={3} xl={3}>
                            <h4 className="mb_heading-3">CONTACT DETAILS</h4>
                            <div className="mb_border-bottom-left"></div>
                            <ul className="mb_ul-contact">
                                <li>
                                    <a href="tel:(941) 483-1816" className="mb_paragraph" title="Call us"><span className="mb_heading-8">Tel: </span>021 685 2625</a>
                                </li>
                                <li>
                                    <span className="mb_heading-8">Address:</span><br />
                                    <a href="https://goo.gl/maps/iMWRxN1SNNVhkLP7A" target="_blank" className="mb_paragraph" title="Open Google Maps" rel="noopener noreferrer"><span className="mb_heading-8">Physical - </span>Belmont Square, Belmont Road , Rondebosch, 7700</a><br />
                                    <a href="https://goo.gl/maps/iMWRxN1SNNVhkLP7A" target="_blank" className="mb_paragraph" title="Open Google Maps" rel="noopener noreferrer"><span className="mb_heading-8">Postal - </span>P.O. Box 382, Rondebosch, 7701</a>
                                </li>
                                <li>
                                    <a href="mailto:info@mbawc.org.za?subject=MBAWC Enquiry" className="mb_paragraph" title="Send us an e-mail"><span className="mb_heading-8">Email: </span>info@mbawc.org.za</a>
                                </li>
                            </ul>
                        </Col>
                        <Col sm={12} md={4} lg={2} xl={2}>
                            <h4 className="mb_heading-3">LEGAL INFORMATION</h4>
                            <div className="mb_border-bottom-left"></div>
                            <ul className="mb_ul-menu">
                                <li>
                                    <a href={bee_certificate ? BASE_URL+bee_certificate.path : pdf} target="_blank" className="mb_paragraph" title="Download" rel="noopener noreferrer" download="B-BBBEE Certificate">{bee_certificate ? bee_certificate.title : 'B-BBBEE Certificate'}</a>
                                </li>
                                <li>
                                    <a href={vat_registration ? BASE_URL+vat_registration.path : pdf3} target="_blank" className="mb_paragraph" title="Download" rel="noopener noreferrer" download="VAT Registration">{vat_registration ? vat_registration.title : 'VAT Registration'}</a>
                                </li>
                                <li>
                                    <a href={company_registration ? BASE_URL+company_registration.path : pdf4} target="_blank" className="mb_paragraph" title="Download" rel="noopener noreferrer" download="Company Registration">{company_registration ? company_registration.title : 'Company Registration'}</a>
                                </li>
                                <li>
                                    <a href={constitution ? BASE_URL+constitution.path : pdf1} target="_blank" className="mb_paragraph" title="Download" rel="noopener noreferrer" download="Constitution">{constitution ? constitution.title : 'Constitution'}</a>
                                </li>
                                <li>
                                    <a href={covid_19_policy ? BASE_URL+covid_19_policy.path : pdf2} target="_blank" className="mb_paragraph" title="Download" rel="noopener noreferrer" download="COVID-19 Policy">{covid_19_policy ? covid_19_policy.title : 'COVID-19 Policy'}</a>
                                </li>
                                <li>
                                    <a href={popia_consent ? BASE_URL+ popia_consent.path : pdf5} target="_blank" className="mb_paragraph" download="POPIA Consent to Use of Personal Information" title="Download">{popia_consent ? popia_consent.title : 'POPIA Consent to Use of Personal Information'}</a>
                                </li>
                            </ul>
                        </Col>
                        <Col sm={12} md={4} lg={2} xl={2}>
                            <h4 className="mb_heading-3">ASSOCIATE LINKS </h4>
                            <div className="mb_border-bottom-left"></div>
                            <ul className="mb_ul-menu">
                                <li>
                                    <a href="https://www.masterbuilders.org.za/" target="_blank" className="mb_no-padding" title="Master Builders South Africa" rel="noopener noreferrer"><img className="mb_logo-footer" src={mbsaLogo} alt="Master Builders South Africa" title="Master Builders South Africa" /></a>
                                </li>
                                <li>
                                    <a href="https://www.fem.co.za/" target="_blank" className="mb_no-padding" title="The Federated Employers Mutual Assurance Company" rel="noopener noreferrer"><img className="mb_logo-footer" src={femLogo} alt="The Federated Employers Mutual Assurance Company" title="The Federated Employers Mutual Assurance Company" /></a>
                                </li>
                                <li>
                                    <a href="https://www.bibc.co.za/" target="_blank" className="mb_no-padding" title="The Building Industry Bargaining Council Cape of Good Hope" rel="noopener noreferrer"><img className="mb_logo-footer" src={tbibcLogo} alt="The Building Industry Bargaining Council Cape of Good Hope" title="The Building Industry Bargaining Council Cape of Good Hope" /></a>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
                <section className="mb_bottom-footer">
                    <Container>
                        <div className="mb_inner">
                            <CopyWrightLinks />
                        </div>
                    </Container>
                </section>
            </footer>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        documents: state.documentsState
    }
}

export default connect(mapStateToProps)(Footer)