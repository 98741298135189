import React from 'react'
import {BASE_URL} from '../../path'

class HealthSafetyItem extends React.Component {
    
    render(){
        const {date, text, link, image} = this.props
        return(
            <div className="mb_news-item">
                <article>
                    {image && <img src={BASE_URL+image} alt="" />}
                    <span className="mb_paragraph-3">{date}</span>
                    <h4 className="mb_heading-3-1">{text}</h4>
                    <a className="mb_read-more-link" href={link} target="_blank" title={text} el="noopener noreferrer">Download</a>
                </article>   
            </div>
        )
    }
}

export default HealthSafetyItem