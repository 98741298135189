import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import EventsItem from '../items/EventsItem'
import {connect} from 'react-redux'
import {fetchEvents} from '../../actions'
import {Pagination} from 'react-laravel-paginex'

class EventsListSection extends React.Component{

    componentDidMount(){
        if(!this.props.events){
            this.props.fetchEvents(null)
        }
    }

    renderList(){
        return this.props.events.data.map((item, index) => {
            if(item.headline){
                return (
                    <React.Fragment>
                    <Col sm={12} md={6} lg={4} xl={4} >
                        <EventsItem
                            date={item.start_date}
                            text={item.headline}
                            link={`/events/${item.slug}`}
                            image={item.image}
                        />
                    </Col>
                    </React.Fragment>
                )
            }        
            return <React.Fragment key={index}></React.Fragment>    
        })
    }

    render(){
        return(
            <section className="mb_news-list-section">
                <Container>
                    <Row>
                        <Col className="mb_search-results">
                            <h4 class="mb_paragraph">{`Found ${this.props.events && this.props.events.meta.total} events: Page ${this.props.events && this.props.events.meta.current_page} of ${this.props.events && this.props.events.meta.last_page}`}</h4>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                         {this.props.events && this.renderList()}
                    </Row>
                </Container>
                 <Container>
                    <Row>
                        <Pagination changePage={this.props.fetchEvents} data={this.props.events} containerClass="mb_pagination" />
                    </Row>
                </Container>
            </section>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        events: state.eventsState.data
    }
}

export default connect(mapStateToProps, {
    fetchEvents
})(EventsListSection)